import { Formik, Form } from 'formik';
import { Button, Box } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import MSA_Presale from '../contract-abi/MetaStreetApesPresale.json';
import toast, { Toaster } from 'react-hot-toast';
import { FormGroup, Input, InputGroupText, InputGroup } from 'reactstrap';
import { useState } from 'react';
interface MyFormValues {
  amount: number;
}

const ModuleFormik: any = ({ signer, account }: any) => {
  const contractAddress: any = '0x463811D73fc2A75d95cE998CA94af351823A4080';
  const initialValues: MyFormValues = { amount: 1 };
  // const { account } = useEthers();
  const [num, setNum] = useState(0);
  const [gasFee, setGasFee] = useState<any>(0);
  const price: number = 0;

  const totalPrice = num * price;

  const estimateGasPrice = async () => {
    const contract = new ethers.Contract(
      contractAddress,
      MSA_Presale.abi,
      // set signer prop here, replace signer
      signer
    );

    const estimation = await contract.estimateGas.buyPrivate(num, {
      // set account here replace in pubkey
      from: account,
      value: ethers.utils.parseEther(totalPrice.toString()),
    });

    const gasPrice: any = ethers.utils.formatUnits(estimation, 'gwei');
    console.log('estimation: ', gasPrice);
    setGasFee(gasPrice);
  };

  const privateMint = async () => {
    const contract = new ethers.Contract(
      contractAddress,
      MSA_Presale.abi,
      signer
    );
    if (num === 0) {
      toast.error('Items must be 1 to 100');
    } else {
      await contract
        .buyPrivate(num, {
          from: account,
          value: ethers.utils.parseEther(totalPrice.toString()),
        })
        .catch((error: any) => {
          toast.error(`${error.message}`);
        });
      contract.on('Buy', (sender_, _numberOfNft) => {
        toast.success(`${_numberOfNft} of items minted by ${sender_}`);
      });
    }
  };
  let incNum = async () => {
    if (num < 100) {
      setNum(Number(num) + 1);
      await estimateGasPrice();
    }
  };
  let decNum = async () => {
    if (num > 0) {
      setNum(num - 1);
      await estimateGasPrice();
    }
  };
  let handleChange = async (e: any) => {
    setNum(e.target.value);
    await estimateGasPrice();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (values.amount > 100) {
            toast.error('Number Cannot greater then 100');
          } else {
            privateMint();
          }
        }}
      >
        <Form className="form">
          <FormGroup>
            <InputGroup>
              <InputGroupText
                onClick={decNum}
                className="custom_btn_input_cursor"
                addonType="prepend"
              >
                <button type="button">-</button>
              </InputGroupText>
              <Input
                value={num}
                className="custom_form_feild text-center"
                min="1"
                max="100"
                name="amount"
                bsSize="lg"
                placeholder="Enter Amount"
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
              <InputGroupText
                className="custom_btn_input_cursor"
                onClick={incNum}
                addonType="append"
              >
                <button type="button">+</button>
              </InputGroupText>
            </InputGroup>
            {account === undefined ? (
              <Box mt="0.5rem">
                <Button
                  // color="black"
                  // bg="white"
                  disabled
                  className="butonDisabled "
                  width="100%"
                  p="1.62rem"
                  type="submit"
                  borderRadius="1.25rem"
                  _hover={{ bg: 'rgb(255,165,0)' }}
                >
                  Connect Metamask
                </Button>
              </Box>
            ) : (
              <>
                <Box mt="0.5rem">
                  <Button
                    color="white"
                    bg="black"
                    width="100%"
                    p="1.62rem"
                    type="submit"
                    borderRadius="1.25rem"
                    className="buton"
                    _hover={{ bg: 'rgb(255,165,0)' }}
                  >
                    Mint
                  </Button>
                </Box>
                <Box className="text-center text-white gas_details" mt="0.5rem">
                  Private Sale Price: {'  '}
                  {price + ' * ' + num + ' = ' + price * num + ' ETH'}
                  <br />
                  Gas Fee: {parseFloat(gasFee) + ' ETH'}
                  <br />
                  Total Price:{' '}
                  {parseFloat(totalPrice.toString()) +
                    parseFloat(gasFee) +
                    ' ETH'}
                </Box>
              </>
            )}
          </FormGroup>
        </Form>
      </Formik>

      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
    </div>
  );
};
export default ModuleFormik;
