import { Box, Text, useDisclosure } from '@chakra-ui/react';

import { Container, Row, Col } from 'reactstrap';

import TokenModal from './Modal/TokenModal';
import Formik from './Form';

export default function Trade({ signer, account }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Text className="form_heading_text text-center header-text-mintlive">
              MINT IS LIVE
            </Text>
            <Box
              className="custom_margin_top custom_box shadow-lg"
              mx="auto"
              mt="5.25rem"
              boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
              borderRadius="1.37rem"
            >
              <TokenModal isOpen={isOpen} onClose={onClose} />

              <Text
                className="form_heading"
                color="black"
                background="white"
                fontWeight="500"
              >
                NFT Quantity
              </Text>
              <Formik signer={signer} account={account} />
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  );
}
