import '../global.css';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import discord from '../../src/assets/discord-48.png';
import openSea from '../../src/assets/opensea.png';
import instagram from '../../src/assets/instagram.png';
import twitter from '../../src/assets/twitter.png';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import metaMaskIcon from '../assets/metamask-icon.png';
import trustWalletIcon from '../assets/trustwallet-icon.png';
import { useState } from 'react';
// send signer and account state to form.tsx

export default function Layout({
  handleMetaMask,
  handleTrustWallet,
  disconnectWallet,
  account,
}: any) {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  return (
    <div>
      <Navbar className="my-3" color="transparent" expand="md">
        <NavbarBrand
          className="header_logo header-text"
          href="http://metastreetapes.com/"
        >
          {' '}
          META STREET APES{' '}
        </NavbarBrand>
        {/* <NavbarToggler /> */}
        <Nav className="ml-auto header_social" navbar>
          {/* <NavItem>
            <NavLink onClick={handleMetaMask} className="custom_button mt-2">
              {' '}
              {account === undefined
                ? 'Connect Metamask'
                : `${account.slice(0, 4) + '...' + account.slice(35, 42)}`}{' '}
            </NavLink>
          </NavItem> */}
          <NavItem>
            {account === undefined ? (
              <NavLink className="custom_button mt-2" onClick={toggleShow}>
                Connect Wallet
              </NavLink>
            ) : (
              <NavLink
                className="custom_button mt-2"
                onClick={disconnectWallet}
              >
                {account.slice(0, 4) + '...' + account.slice(35, 42)}
              </NavLink>
            )}
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
              <MDBModalDialog>
                <MDBModalContent>
                  <MDBModalBody>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          handleMetaMask().then(toggleShow());
                        }}
                        className="custom_button mt-2"
                      >
                        <img className="wallet-icon" src={metaMaskIcon} />{' '}
                      </button>
                      <button
                        onClick={() => {
                          handleTrustWallet().then(toggleShow());
                        }}
                        className="custom_button mt-2"
                      >
                        <p>
                          <img className="wallet-icon" src={trustWalletIcon} />{' '}
                        </p>{' '}
                      </button>
                    </div>
                  </MDBModalBody>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </NavItem>
          <NavItem>
            <NavLink
              href="https://discord.com/invite/38q5nupVwc"
              target="_blank"
            >
              <img className="image-size" src={discord} alt="" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="https://testnets.opensea.io/collection/name-fep3tcqxzo"
              target="_blank"
            >
              <img className="image-size" src={openSea} alt="" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="https://www.instagram.com/metastreetapes/?utm_medium=copy_link"
              target="_blank"
            >
              <img className="image-size" src={instagram} alt="" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://twitter.com/metastreetapes" target="_blank">
              <img className="image-size" src={twitter} alt="" />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
}
