import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import theme from './theme';
import Header from './components/Header';
import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { Web3Provider } from '@ethersproject/providers';
import AccountModal from './components/Modal/AccountModal';
import { ethers } from 'ethers';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Swap from './components/Swap';
import {
  Navbar,
  NavbarBrand,
  NavLink,
  ListGroup,
  ListGroupItem,
  Container,
} from 'reactstrap';
import '@fontsource/inter';
import discord from '../src/assets/discord-48.png';
import openSea from '../src/assets/opensea.png';
import instagram from '../src/assets/instagram.png';
import twitter from '../src/assets/twitter.png';

import './global.css';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [account, setAccount]: any = useState();
  const [signer, setSigner]: any = useState();

  async function handleMetaMask() {
    if (window.ethereum) {
      // Get provider from Metamask
      const provider: any = new ethers.providers.Web3Provider(window.ethereum);
      // Set signer
      const network = provider.getNetwork();
      if ((await network).chainId !== 1) {
        toast.error('Please connect to Ethereum Mainnet');
      } else {
        const signerAddress = provider.getSigner();
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        console.log(accounts);
        setAccount(accounts.toString());
        setSigner(signerAddress);
      }
    } else {
      window.open('https://metamask.io/download/', '_blank');
    }
  }

  async function handleTrustWallet() {
    try {
      const RPC_URLS = {
        1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
      };
      const provider = new WalletConnectProvider({
        rpc: {
          1: RPC_URLS[1],
        },
        qrcode: true,
        // pollingInterval: 15000
      });
      const accounts = await provider.enable();
      const accountAddres = accounts[0];

      const library = new Web3Provider(provider, 'any');
      const signerAddress = library.getSigner();
      setAccount(accountAddres);
      setSigner(signerAddress);
    } catch (ex) {
      console.log(ex);
    }
  }

  const disconnectWallet = () => {
    setAccount(undefined);
    setSigner(undefined);
  };

  return (
    <>
      <ChakraProvider theme={theme}>
        <Header
          handleMetaMask={handleMetaMask}
          handleTrustWallet={handleTrustWallet}
          disconnectWallet={disconnectWallet}
          account={account}
        />
        <AccountModal isOpen={isOpen} onClose={onClose} />
        <div className="main_section">
          <Swap signer={signer} account={account} />
        </div>

        <div className="fixed-bottom footer_btm">
          <div className="footer_social_links">
            <ListGroup horizontal>
              <ListGroupItem>
                <NavLink
                  href="https://discord.com/invite/38q5nupVwc"
                  target="_blank"
                >
                  <img className="image-size" src={discord} alt="" />
                </NavLink>
              </ListGroupItem>
              <ListGroupItem>
                <NavLink
                  href="https://testnets.opensea.io/collection/name-fep3tcqxzo"
                  target="_blank"
                >
                  <img className="image-size" src={openSea} alt="" />
                </NavLink>
              </ListGroupItem>
              <ListGroupItem>
                <NavLink
                  href="https://www.instagram.com/metastreetapes/?utm_medium=copy_link"
                  target="_blank"
                >
                  <img className="image-size" src={instagram} alt="" />
                </NavLink>
              </ListGroupItem>
              <ListGroupItem>
                <NavLink
                  href="https://twitter.com/metastreetapes"
                  target="_blank"
                >
                  <img className="image-size" src={twitter} alt="" />
                </NavLink>
              </ListGroupItem>
            </ListGroup>
          </div>

          <Navbar color="transparent" dark className="text-center">
            <Container>
              <p>
                © 2022 META STREET APES | All Rights Reserved Powered By
                <NavbarBrand>The Game Storm Studios Pvt. Ltd.</NavbarBrand>
              </p>
            </Container>
          </Navbar>
        </div>
      </ChakraProvider>
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 6000,
          success: {
            duration: 3000,
          },
        }}
      />
    </>
  );
}

export default App;
